import {
  setMinutes,
  setHours,
  isAfter,
  addMinutes,
  getHours,
  getMinutes,
  getTime,
  compareAsc,
  startOfMinute,
  isWeekend,
  addDays,
  format,
  addMonths,
  getMonth,
  getDate,
  getYear,
  isPast,
  getDay
} from 'date-fns'
import { ErrorMessages } from '../configs/errorMessages'


import { immerSet } from './useStore'

// utils
const DAY_MS = 24 * 60 * 60 * 1000
const SUNDAY = 0

export const isOpenDayForLocation = location => tsOrDate => {
  if (!location) return false

  // close weekend?
  if (location['closedWeekend'] && isWeekend(tsOrDate)) return false

  // close sunday?
  if (location['closedSunday'] && getDay(tsOrDate) === SUNDAY) return false

  // close holidays?
  // "closedHolidays": [{ name: 'EndOfYear', startEnd: [1671195600, 1673182800] }]
  // startEnd[0]: start of first holiday day
  // startEnd[1]: end of last holiday day
  const holidays = location['closedHolidays']
  if (holidays && holidays.length > 0) {
    for (let i = 0; i < holidays.length; i++) {
      if (tsOrDate > holidays[i]['startEnd'][0] && tsOrDate < holidays[i]['startEnd'][1])
        return false
    }
  }

  // casual closing days?
  const closedDatesYears = location['closedDates']
  if (!closedDatesYears) return true

  const year = getYear(tsOrDate)
  const closedDates = closedDatesYears.find(el => el.year === year)
  if (!closedDates) return true

  const dates = closedDates['dates']
  if (!dates) return true

  for (let i = 0; i < dates.length; i++) {
    if (tsOrDate >= dates[i] && tsOrDate < dates[i] + DAY_MS) return false
  }
  return true
}


// Rule: weekend are closed days
// export const isOpenDay = tsOrDate => {
//   return !isWeekend(tsOrDate)
// }

export const isOpenTime = (dtOpen, dtClose) => tsOrDate => {
  return compareAsc(dtOpen, tsOrDate) === -1 && compareAsc(tsOrDate, dtClose) === -1
}

export const getOpenDateTimeForDate = location => date => {
  if (!isOpenDayForLocation(location)(date)) {
    return [null, null]
  }

  const [[openH, openM], [closeH, closeM]] = location.openTime || [[11, 0], [15, 45]]
  const dtOpen = setHours(setMinutes(date, openM), openH)
  const dtClose = setHours(setMinutes(date, closeM), closeH)
  return [dtOpen, dtClose]
}

export const tsPickupUpdate = dt => immerSet(state => {
  state.tsPickup = getTime(dt)
  console.log('tsPickupUpdate state.tsPickup', state.tsPickup)
})

export const tsPickupUpdateDate = dateOnly => immerSet(state => {
  const dt = new Date(getYear(dateOnly), getMonth(dateOnly), getDate(dateOnly),
    getHours(state.tsPickup), getMinutes(state.tsPickup))
  state.tsPickup = getTime(dt)
  console.log('tsPickupUpdateDate state.tsPickup', state.tsPickup)
})

export const isValidPickupDateTimeForLocation = (tsPickup, location) => {
  const [dtOpen, dtClose] = getOpenDateTimeForDate(location)(new Date(tsPickup))

  // is open day
  if (!isOpenDayForLocation(location)(tsPickup)) {
    const dtNextOpen = nextOpenDayForLocation(location)(tsPickup)
    if (dtNextOpen) {
      return new Error(ErrorMessages.OutOfWorkingDays +
        `. Next open day is: ${format(dtNextOpen, 'iiii, d/MM')}.`)
    }
    return new Error(ErrorMessages.OutOfWorkingDays)
  }
  // time passed
  if (isPast(tsPickup)) {
    return new Error(ErrorMessages.PickupTimePassed)
  }

  if (!isOpenTime(dtOpen, dtClose)(tsPickup)) {
    return new Error(ErrorMessages.OutOfWorkingHours)
  }

  return tsPickup
}

export const isFutureDate = date => isAfter(date, Date.now())

// Helpers

export const cal20MinAhead = (open, close) => {
  const dt = new Date()
  const min = Math.round((getMinutes(dt) / 10)) * 10
  const dtAhead = addMinutes(startOfMinute(dt), min + 20)

  if (compareAsc(dtAhead, open) === -1) return open
  if (compareAsc(dtAhead, close) === 1) return close
  return dtAhead
}

// search in next 2 weeks, is any working day. Otherwise, return null
export const nextOpenDayForLocation = location => date => {
  for (let i = 1; i < 14; i++) {
    const dt = addDays(date, i)
    if (isOpenDayForLocation(location)(dt)) {
      return dt
    }
  }
  return null
}

// 1 + 1 months max
export const calendarMaxDate = (dt) => {
  return addMonths(dt, 1)
}

export const isLater = (dt, dtNow) => {
  return compareAsc(dt, dtNow) === -1
}