import { useEffect } from 'react'
import { useStore } from '../states/useStore'

import { usePrefsStore } from '../states/usePrefsStore'
import ChkoutButton from './ChkoutButton'
import { Badge, Box, Center, Container, Divider, Flex, GridItem, Input, Select, SimpleGrid, Spacer, VStack } from '@chakra-ui/react'
import { locationUpdate } from '../states/locationActions'
import { debouncedCustomerNameUpdate, debouncedCustomerPhoneUpdate } from '../states/customerActions'


import Item from './Item'
import { itemNoText } from '../states/cartActions'
import PickupTime from './PickupTime'


const Customer = ({ nameValid = true, phoneValid = true }) => {
  const [name, phone] = useStore(state => [state.name, state.phone])

  return (
    <>
      <Badge color='brand.700'>Contact</Badge>
      <SimpleGrid columns={2} columnGap={3} rowGap={2} w="full">
        <GridItem colSpan={1}>
          <Input isInvalid={!nameValid} onKeyUp={e => debouncedCustomerNameUpdate(e.target.value)} defaultValue={name} placeholder='Name' size='sm' />
        </GridItem>
        <GridItem colSpan={1}>
          <Input isInvalid={!phoneValid} onKeyUp={e => debouncedCustomerPhoneUpdate(e.target.value)} defaultValue={phone} placeholder='Phone' size='sm' />
        </GridItem>
        {/* <GridItem colSpan={2}>
      <Input isInvalid={!emailValid} onKeyUp={e => debouncedCustomerEmailUpdate(e.target.value)} defaultValue={email} placeholder='Email' size='sm' />
    </GridItem> */}
      </SimpleGrid>
    </>
  )
}

const Location = () => {
  const locId = useStore(state => state.locId)
  const locations = usePrefsStore(state => state.locations)
  const locHandle = e => locationUpdate(e.target.value)

  useEffect(() => {
    const isOnlyOneLocation = () => locations && locations.length === 1

    // if only 1 location, always select it
    if (isOnlyOneLocation()) {
      const oneLocId = locations[0].locId
      if (!locId || locId !== oneLocId) {
        console.log('locationUpdate locId oneLocId', locId, oneLocId)
        locationUpdate(oneLocId)
      }
    }
  }, [locId, locations])

  return <Select value={locId} onChange={locHandle} placeholder='Select location' size='sm' py='2' >
    {locations.map(loc => <option value={loc.locId} key={loc.locId}>{loc.address}</option>)}
  </Select>
}

const Pickup = () => {
  return (
    <Flex w='full' px='0' pt='1' direction='column' alignItems='flex-start'>
      <Badge color='brand.700'>Pickup at</Badge>
      <Location />
      <Flex w='full' mt='2' alignItems='center' justifyContent='space-between'>
        <Badge mr='2' color='brand.700'>ON</Badge>
        <Spacer />
        <PickupTime />
      </Flex>
    </Flex>
  )
}

const Items = () => {
  const cart = useStore(state => state.cart)

  return (
    <Flex direction='column' w='full' pt='2'>
      <Flex alignItems='center'>
        {cart && cart.length > 0 && <Badge color='brand.700'>Cart</Badge>}
        <Spacer />
        <Box mr='2' fontSize='sm' fontWeight='500' color='brand.600'>
          {itemNoText(cart)}
        </Box>
      </Flex>
      {cart.map(item => <Item item={item} key={item.itemId} />)}
    </Flex>
  )
}

const Cart = () => {
  // const locId = useStore(state => state.locId)
  // const location = getLocation(locId)
  return (
    <Container mt='2'>
      <VStack h='95vh' overflowY='scroll' w='full' alignItems='flex-start'>
        <Customer />
        <Divider />
        <Pickup />
        <Divider />
        <Center
          borderRadius='5'
          fontWeight='600'
          color='white'
          bg='red.600'
          h='60px'
          w='full'
          p='2'
        >
          <ChkoutButton />
        </Center>
        <Items />
      </VStack>
    </Container>
  )
}

export default Cart