import { useMatch } from 'react-router-dom'
import QRCode from 'qrcode.react'

import { calTotal, itemNoText } from '../states/cartActions'
import { usePrefsStore } from '../states/usePrefsStore'

import { Box, Button, Container, Flex, HStack, Spacer } from '@chakra-ui/react'
import { optionArrayToText } from '../common/utils'
import { useTrackingOrderById, useTrackingOrderStatusById } from '../states/useRemoteStates'

const toText = selected =>
  Object.keys(selected).reduce(
    (acc, type) => acc + `<div><span>${type}:</span>${optionArrayToText(selected[type])}</div>`,
    ''
  )

const SelectedToText = ({ selected }) => {
  return (
    <Box
      sx={{
        '> div > span': {
          mr: '1',
          color: 'red.500',
          fontSize: 'sm',
          fontWeight: '500'
        }
      }}
      isTruncated
      dangerouslySetInnerHTML={{ __html: toText(selected) }} />
  )
}

const ItemOptions = ({ item }) => {
  return (
    <Box my='2'>
      <SelectedToText selected={item.selectedOptions} />
    </Box>
  )
}

const ItemExtras = ({ item }) => {
  // const noExtra = { key: 'NONE', name: 'No extra...' }
  const { name, price } = item.selectedExtra
  const qty = item.qty // qty of extra === qty of item
  return (
    <Flex my='3'>
      <Box textStyle='extraTitle'>{name}</Box>
      <Spacer />
      <Box textStyle='itemTitle'>${price}</Box>
      <Box mx='1'>x</Box>
      <Box textStyle='itemTitle'>{qty}</Box>
    </Flex>
  )
}

const Item = ({ item }) => {
  const isAnyOptions = item => item.selectedOptions
  const isAnyExtra = item => item.selectedExtra
  return (
    <Box
      m='3' p='3'
      border='1px'
      borderRadius='md'
      bg='gray.50'
      id={item.itemId}
    >
      <Flex>
        <Box textStyle='itemTitle'>{item.name}</Box>
        <Spacer />
        <Box textStyle='itemTitle'>${item.price}</Box>
        <Box mx='1'>x</Box>
        <Box textStyle='itemTitle'>{item.qty}</Box>
      </Flex>
      {isAnyExtra(item) && <ItemExtras item={item} />}
      {isAnyOptions(item) && <ItemOptions item={item} />}
    </Box>
  )
}

const Tracking = () => {
  // params 'orderId':  /:orderId
  const match = useMatch('tracking/:orderId')
  const orderId = match.params.orderId

  const { data: order } = useTrackingOrderById(orderId)
  console.log('data: order', order)

  // use this query to keep status update
  const { data: { status }, refetch } = useTrackingOrderStatusById(orderId)

  const statusNames = usePrefsStore(state => state.statusNames)
  const statusColors = usePrefsStore(state => state.statusColors)

  const {
    orderNumber,
    locAddress,
    cart
  } = order

  // The order NOT FOUND
  if (!orderNumber) {
    return null
  }

  // FOUND order
  return (
    <Container>
      <Flex w='full' my='3' px='6' direction='column'
        border='1px solid black'
        borderRadius='md'
        boxShadow='md'
        bgGradient='linear(to-t, brand.100, white)'
      >
        <Flex m='2' w='full' px='1' direction='column' alignItems='center'>
          <Box m='2' fontSize='lg' fontWeight='600'>DIGITAL RECEIPT</Box>
          <QRCode value={JSON.stringify(`${process.env.REACT_APP_API_URL}/#/tracking/${orderId}`)} />
          <Box fontSize='md' fontWeight='400'>{orderNumber}</Box>
        </Flex>
        <HStack
          w='full' my='2' fontSize='md' fontWeight='600'
          alignItems='center' justifyContent='space-between'
        >
          <Box>{`#${orderNumber.substr(orderNumber.length - 4, 4)}`}</Box>
          <Button variant='solid' onClick={refetch}
            color={`${statusColors[status] || 'black'}`} >
            {statusNames[status]}
          </Button>
        </HStack>
        <Box my='2'>
          <Box textStyle='sectionTitle'>Pickup point:</Box>
          <Flex direction='column'>
            <Box textStyle='normalTextBlue'>{locAddress}</Box>
          </Flex>
        </Box>
        <Box my='2'>
          <Flex>
            <Box textStyle='sectionTitle'>Items</Box>
            <Spacer />
            <Box textStyle='normalTextBlue'>${calTotal(cart)} / {itemNoText(cart)}</Box>
          </Flex>
          <div>
            {cart && cart.map((item, idx) =>
              <Item key={idx} item={item} />)}
          </div>
        </Box>
      </Flex>
    </Container>
  )
}

export default Tracking