import { loadStripe } from '@stripe/stripe-js'
import { Center, Text } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { calTotal } from '../states/cartActions'

import { constructOrder, orderAdd } from '../states/ordersActions'
import { useOrderCreate } from '../states/useRemoteStates'
import { useStore } from '../states/useStore'

const stripeRedirect = sessionId => {
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    .then(stripe => stripe.redirectToCheckout({ sessionId }))
    .then(result => result.error ?
      Promise.reject(new Error(result.error.message || 'Could not redirect to checkout'))
      :
      result
    )
  // .catch(console.log)
}

const warningToast = toast => message => {
  toast({
    title: message,
    status: 'warning',
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })
}

const ChkoutButton = () => {
  const toast = useToast()
  const warning = warningToast(toast)

  const [submitting, setSubmitting] = useState(false)

  const orderCreate = useOrderCreate()
  const cart = useStore(state => state.cart)

  const handleChkout = e => {
    e.preventDefault()

    if (submitting) {
      console.log('submitting...')
      return
    }

    setSubmitting(true)
    toast.closeAll()

    // preliminary validate and construct order data
    const order = constructOrder()
    if (order instanceof Error) {
      setSubmitting(false)
      warning(order.message)
      return
    }
    // post order to server
    orderCreate.mutate(order, {
      onSuccess: ({ data }) => {
        if (data.sessionId) {
          // save the order returning from server
          orderAdd(data)
          // redirect to Stripe Checkout page
          stripeRedirect(data.sessionId)
            .catch(err => {
              warning('Server not responded. Please try again later!')
            })
        }
      },
      onError: console.log, // to be send error message
      onSettled: () => {
        // delay enable checkout button
        setTimeout(() => {
          setSubmitting(false)
        }, 1000)
      }
    })
  }

  return (
    submitting ?
      <Text color='cyan'>Checking... Please wait</Text>
      :
      <Center w='full' cursor='pointer' onClick={handleChkout}>
        Checkout ${calTotal(cart)}
      </Center>
  )

}

export default ChkoutButton